header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
	color: white;
	padding: 65px;
	width: 100%;
	
	@media only screen and (max-width: 1024px) and (orientation: portrait){
		padding: 65px 50px;
	}
	@media only screen and (max-width: 768px) {
		padding: 45px;
	}
	@media only screen and (max-width: 700px) {
		padding: 25px;
		padding-top: 40px;
	}

	a.logo {
		float: left;

		img {
			width: 180px;

			&+img{
				display: none;
			}
			

			@media only screen and (max-width: 1024px) and (orientation: portrait){
				width: 230px;
			}
			@media only screen and (max-width: 750px){
				width: 130px;
			}

		}
	}
	a.burger_menu{
		@media only screen and (max-width: 1240px) {
			display: block;
		    position: relative;
		    height: 35px;
		    width: 100%;
		    width: 35px;
		    float: right;

		    &:hover{

		    	span{
		    		&:before{
		    			top: -12px;
		    		}
		    		&:after{
		    			top: 12px;
		    		}
		    	}
		    }
			span{
				height: 2px;
			    width: 30px;
			    background-color: #FFF;
			    display: block;
			    position: absolute;
			    right: 5%;
			    top: 44%;
			    @media only screen and (max-width: 768px) and (orientation: portrait) {
					right: 44%;
				}
				@media only screen and (max-width: 510px){
					right: 0;
				}
			    &:before{
					height: 2px;
				    width: 30px;
				    background-color: #FFF;
				    position: absolute;
				    top: -10px;
		    		right: 0;
				    content: " ";
				    transition: all .2s ease-in-out;


				}
				&:after{
					height: 2px;
				    width: 30px;
				    background-color: #FFF;
				    position: absolute;
				    top: 10px;
		    		right: 0;
				    content: " ";
				    transition: all .2s ease-in-out;
				}
			}
		}
	}
	
	
	ul {

		padding: 15px 0px;
		margin: 0;
		list-style: none;
		text-align: right;
		@media only screen and (max-width: 1240px) {
			display: none;
			padding: 7% 0px;

		}

		li {
			padding: 0;
			margin: 0;
			display: inline-block;
			padding-left: 35px;
			position: relative;
			cursor: pointer;

				&:last-of-type {
					a:after {
						content: none;
					}
				}
			&.menu_actif{
				@media only screen and (max-width: 415px) {
					a{
						color: #f45c43;
					}
				}
				a:after{
					content: '';
					position: absolute;
					bottom: -5px;
					width: 55px;
					left: 0;
					right: 0;
					margin: 0 auto;
					height: 1px;
					background: white;
					opacity: 1;
					transition: .3s ease;
				}

			}

			a {
				position: relative;
				transition: .3s ease;


				&:after {
					content: '';
					position: absolute;
					bottom: -8px;
					width: 25px;
					left: 0;
					right: 0;
					margin: 0 auto;
					height: 1px;
					background: white;
					opacity: 0;
					transition: .3s ease;
				}
				&:hover {
					&:after {
						opacity: 1;
						width: 55px;
					}
				}
			}


			@media only screen and (max-width: 1024px) {
				display: none;

				&:last-of-type {
					display: inline-block;
				}
			}

			ul.submenu {
				padding: 15px 0px;
				margin: 0;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: auto;
				text-align: left;
				display: table;
				background: white;
				top: 60px;
				
				opacity: 0;
				height: 1px;
				
				display: block;
				overflow: hidden;
				transform: translateX(-50%);

				@media only screen and (max-width: 1240px) {
					position: initial;
				}
				
				&:after {
					content: '';
					position: absolute;
					height: 30px;
					width: 30px;
					background: white;
					transform: rotate(45deg);
					left: 0;
					right: 0;
					top: -15px;
					margin: 0 auto;

					@media only screen and (max-width: 1240px) {
						display: none;

					}

				}

				li {
					display: block;
					background: white;
					padding: 0;
					white-space: nowrap;

					@media only screen and (max-width: 1024px) {
						background: none;
					}


					&:last-of-type a {
						background: none;
						// padding: 12px 40px;
						font-weight: 300;
					}

					a {
						color: black;
						padding: 12px 40px;
						display: block;
						font-style: initial;
						transition: .2s ease;
						font-weight: 300;

						&:after{
							position: absolute;
							height: 1.8px;
							background: red;
							width: 0px;
							content: "";
							left: 0;
							bottom: 5px;
							opacity: 1;
							margin: inherit;
							margin: 0 40px;
							transition: .3s ease;
							background-color: #000;
						}

						&.active{
							position: relative;
							color: red;
							// &:after{
							// 	position: absolute;
							// 	height: 1.8px;
							// 	background: red;
							// 	width: 50px;
							// 	content: "";
							// 	left: 0;
							// 	bottom: 5px;
							// 	opacity: 1;
							// 	margin: inherit;
							// 	margin: 0 40px;
							// }
						}

						&:hover {
							//font-weight: 600;
							color: red;
							&:after{
								position: absolute;
								height: 1.8px;
								background: red;
								width: 50px;
								content: "";
								left: 0;
								bottom: 5px;
								opacity: 1;
								margin: inherit;
								margin: 0 40px;
								background-color: red;

								@media only screen and (max-width: 415px) {
									display: none;

								}
							}
						}						
					}
				}
			}

			a {
				color: inherit;
				text-decoration: none;
				font-weight: 600;
				font-style: italic;
				font-size: 18px;

				@media only screen and (max-width: 1400px) {
					font-size: 14px;
				}
				@media only screen and (max-width: 1240px) {
					color: #000;
				}			
			}

			&:last-of-type a {
				background: linear-gradient(135deg, #eb3349, #f45c43,#d8553f);
				background-size: 200% 100%;
				background-position: 0 0;
				padding: 15px 30px;
				font-weight: 600;
				display: block;
				transition: .3s ease;

				&:hover{
					background-position: 100% 0;
				}
			}
		}
	}
	
	& > ul > li.dropdown {
	
		position: relative;

		@media only screen and (max-width: 1240px) {
			display: none;
		}

		
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 400%;
			opacity: 0;
			transform: translateY(0%);
			@media only screen and (max-width: 1240px) {
					display: none;
				}
		}
			
		ul {
			display: table;
			transition: opacity .4s;
			opacity: 1;
			height: auto;
			overflow: visible;
			@media only screen and (max-width: 1240px) {
				display: none;
			}
		}
	}
}
.moved_menu{
	display: block;
    overflow-y: scroll;
    width: 100%;
    height: 74%;
    overflow-x: hidden;

    @media only screen and (max-width: 320px) {
		height: 82%;
	}

    >li:last-child{
    	left: 50%;
	    padding-left: 0;
	    transform: translate(-50%,-50%);
	    position: absolute;
	    z-index: 10000;
	    bottom: 5%;
		
		@media only screen and (max-width: 1024px) and (orientation: landscape){
		    bottom: 0%;
		}
	    @media only screen and (max-width: 768px) {
		    bottom: 2%;
		}
		@media only screen and (max-width: 430px) {
		    bottom: 0;
		}

	    @media only screen and (max-width: 320px) {
		    bottom: -20px;
		}

	    a{
	    	color: #FFF;
	    }
    }
}
header.burger_actif{
		background-color:#FFF;
		height: 100vh;
		position: fixed;
		opacity: 1;
		transition:.5s;
		z-index: 1000000;




		a.burger_menu{
		@media only screen and (max-width: 1240px) {

			&:hover{
				span{
		    		&:before{
		    			top: -10px;
		    		}
		    		&:after{
		    			top: 10px;
		    		}
		    	}
			}

			span{
			    background-color: transparent;
			    


			    &:before{
					background-color: #000;
					transform: rotate(45deg) translate(5px, 9px);

					
				}
				&:after{
				   background-color: #000;
					transform: rotate(-45deg) translate(5px, -9px);

				}

			}
		}
	}

		
		.logo img {
			display: none;

			&+img{
				display: block;
			}
		
		}

		.landing-bg {
	        position: absolute;
	        bottom: 0;
	        right: 0;
	        left: 0;
	        width: 100%;
	        height: 13rem;
	        transform: $skew;
	        transform-origin: top left;
	        overflow: hidden;
	        transform: rotate(0);
	        padding-top: 0rem;
			@media only screen and (max-width: 1024px) and (orientation: portrait){
		        height: 23rem;
		    }
		    @media only screen and (max-width: 480px) {
		        height: 9rem;
		    }
	        @media only screen and (max-width: 360px) {
		        display: none;
		    }

	        .landing-bg-img {
	            transform: $counter-skew;
	            transform-origin: top left;
	            width: 100%;
	            height: 100%;
	            top: 0;
	            left: 0;
	            background-image: url('../img/iStock-697047716.jpg');
	            background-size: cover;
	            background-position: 50% 0%;
	            background-repeat: no-repeat;
	            background-attachment: fixed;
	            mix-blend-mode: multiply;
	            background-position: center !important; 
	        }
      }
    .menu_list li{
    	display: block;
    	text-align: left;
    	padding: 0;
    	&.submenu{
		   	&:after{
		   		content: "";
			    position: absolute;
			    top: 50%;
			    transform: translateY(-50%) rotate(135deg);
			    height: 8px;
			    width: 8px;
			    right: 30px;
			    border-top: 2px solid #f45c43;
			    border-right: 2px solid #f45c43;
			    transition: .3s ease;
			    top: 5px;
		   	}
		   // 	&.menu_actif{
		   // 		&:after{
		   // 			content: "";
					// position: absolute;
					// bottom: -5px;
					// width: 25px;
					// left: 0;
					// right: 0;
					// margin: 0 auto;
					// height: 1px;
					// background: #fff;
					// opacity: 1;
		   // 		}
		   // 	}
		  }

    	&:last-child{
    		a{
    			opacity: 1;
    		}
    	}
    	a{
    		display: block;
    		font-size: 20px;
    		margin-top: 30px;
    		margin-bottom: 0;
    		opacity: .4;

    		@media only screen and (max-width: 320px) {
		    	font-size: 14px;
			}

  
    	}
    	.menu_link-actif{
    		opacity: 1;
    	}
		
	    ul{
	    	left: 18px;
		    display: block;
		    transition: opacity .4s;
		    opacity: 1;
		    height: auto;
		    overflow: visible;
		    transform:none;
		    background: none;
		    padding-left: 2rem;
		    margin-left: 0;
		    display: none;
		    position: relative;
		    top: 0;
		    padding-top: 0;
		    padding-bottom: 0;


		    &:before{
		    	height: 100%;
			    width: 2px;
			    background-color: #eb3349;
			    position: absolute;
			    left: -60px;
			    content: "";
			    transition:1.1s;
			    top: 0;
			    opacity: 0;	  
		    }
		    &.opened::before{
		    	left: 0px;
		    	opacity: 1;
		    }

		    li{
		    	background: none;

		    	a{
		    		padding: 0;
		    		font-size: 16px;
		    		margin: 1rem 0;
		    		opacity: .4;

		    		&:last-child{
		    			opacity: .4;
		    		}
		    	}
		    }
	  	}
	  	
		.db{
			display: block;
		}
	}  
}
