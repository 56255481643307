section.content div.content-container {
	
	@at-root {
		.preprod & {
			margin-top: 150px;
		}
	}
	
	&.search {

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		&:after {
			content: "";
			width: 30%;
		}
		
		h2 {
			width: 100%;
		}
		
		.content-container-block { 
			
			width: 30%;
			margin-bottom: 6%;
			position: relative;

			.button_link  {
                    text-decoration: none;
                    color: white;
                    background: linear-gradient(135deg, $primary-color, $secondary-color);
                    padding: 20px 40px;
                    font-style: italic;
                    font-weight: 500;
                    font-size: 20px;
                    margin-top: 15px;
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding-right: 60px;
                    transition: .3s ease;
                    //box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.3);

                    &.notext, &.small {
                        height: 43px;
                        width: 43px;
                        padding: 0;
                        transition: .3s ease;
                        
                        color: transparent;
                        overflow: hidden;
                        text-indent: -100vw;
                        
                        &:hover {
	                      &:after {
	                        right: 16px;
	                      }
                  		}
						
						&:after {
			            	content: '';
			            	position: absolute;
			            	top: 50%;
			            	transform: translateY(-50%) rotate(45deg);
			            	height: 10px;
			            	width: 10px;
			            	right: 20px;
			            	border-top: 3px solid white;
			            	border-right: 3px solid white;
			            	transition: .3s ease;
			        	}
                    } 
                }

			@media only screen and (max-width: 768px) {
				width: 45%;
			}
			
	        div.content-container-block-img {
	            
	            height: 180px;
	
	            &:after {
	                height: 60px;
	            }
	            
	            img {
		            width: 100%;
		            height: 100%;
		            object-fit: cover;
	            }
	        }
	
	        div.content-container-block-txt {
	            /* padding: 20px; */
			    margin-top: -20px;
			    /* padding-top: 10px; */
			    /* padding-top: 10px; */
			    padding-bottom: 20px;
			    padding-left: 30px;
			    padding-top: 10px;
			    padding-bottom: 30px;

	            @media only screen and (max-width: 768px) {
					padding-bottom: 60px;
				}
				@media only screen and (max-width: 320px) {
					padding-bottom: 60px;
				    width: 100%;
				    padding: 0;
				    padding-left: 5px;
				    padding-bottom: 5rem;
				    padding-right: 5px;
				}
	
	            h3 {
	                font-size: 16px;
	                margin-bottom: 0px;
	                min-height: 35px;

	                @media only screen and (max-width: 320px) {
					    font-size: 15px;
    					margin-bottom: 0;
    					text-align: center;
					}
	            }
	
	            a.notext, a.small {
	                
	                height: 45px;
	                width: 45px;
	                
					color: transparent;
					overflow: hidden;
					text-indent: -100vw;
					position: absolute;
				    right: 0;
				    bottom: 0;
	
	                &:after {
	                    right: 17px;
	                    transition: .3s ease;
	                }
	
	                &:hover {
	                    &:after {
	                        right: 15px; 
	                    }
	                }
	            }
	        }
		}
	}
}