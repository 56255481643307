body.home section.landing div.landing-bg{
  .slideshow {
    position: relative;
    z-index: 1;
    height: 100%;
    margin: 0;
    
    * {
      outline: none;
    }
    
    .slider {
      box-shadow: 0 20px 50px -25px rgba(0, 0, 0, 1);
      height: 100%;
    }
    
    .slider-track {
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    }
    
    .item {
      height: 100vh;
      position: relative;
      z-index: 1;
      div{
          position: absolute;
          top: 75%;
          left: 0;
          right: 0;
          margin: 0 auto;
          transform: skewY(7.5deg);
          color: #fff;
          max-width: 1200px;
          margin: 0 auto;
         
        p{
          color: #fff;
          max-width: 700px;
          font-size: 30px;
          font-weight: 300;
          opacity: 1;
          margin-top: 0;
          @media only screen and (max-width: 1024px){
            font-size: 25px;
            max-width: 600px;
          }
          @media only screen and (max-width: 528px){
            font-size: 20px;
          }
          @media only screen and (max-width: 360px){
            top: 55%;
            font-size: 16px;
            width: 100%;
          }
        }
      }
      
      img {
        width: 100%;
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        transform: skewY(7.5deg);
        object-fit: cover;
        height: 130vh;

      }
      
      &.slick-active img {
        transform: scale(1);
      }
    }
  }
}
.item_text{
position: absolute;
text-shadow: 0px 0px 0px #fff0;
  opacity: 1;
  transition: 1s;
}
.hidden{
color: transparent;
      text-shadow: 0 0 105px #fff0;
  transition: 1s;
  opacity: 0;
  cursor: pointer;
}