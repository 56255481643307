.tools {
    padding-bottom: 1rem;
	
	@media only screen and (max-width: 768px) {
        margin-bottom: 3rem;
    }
    @media only screen and (max-width: 375px) {
        margin-bottom: 0;
    }
    @media only screen and (max-width: 320px) {
        padding-bottom: 4rem;
    }
    
    h3 {
	    margin-top: 1.6em !important;
	    font-size: 25px !important;
    }
    
	label {
		
		position: relative;
		display: block;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 2em;
		font-size: 1.2em;
		font-size: 600;
		float: inherit;
		padding-bottom: 0;
    	padding-top: 20px;
    	padding-left: 0;
		
		@media only screen and (max-width: 768px) {
		    margin-top: 0;
		}
		@media only screen and (max-width: 375px) {
		    margin-top: 0px;
		}
    	@media only screen and (max-width: 320px) {
        	padding: 0;
        }
		
		&:nth-child{
			padding-bottom: 4rem;
		}
		span {
			margin: auto 0;
			margin-bottom: 30px;
		}
		
		input:not([type=checkbox]):not([type=radio]),
		select {
			width: 40%;
			margin-left: 3%;		
			border: 1px solid #CCC;
			margin: 1em 0;
			padding: .5em 2em;
			text-align: right;
			background-color: #FFF;
    		border-left: 2px solid #f25a44;
    		outline: 0;
    		-webkit-appearance: none;
    		border-radius: 0;
    		margin: 5px 0;
		}
		
		&.checkbox {
			justify-content:flex-start;
			align-items: center;
		}
		
		&.long-text {
			align-items: flex-start;
			p {
				width: 90%;
				margin-top: 0;
				
				@media only screen and (max-width: 768px) {
					margin-top: 1em;
				}
				
			}
			input[type=checkbox],
			input[type=radio]{
				margin-top: 9px;
			}
		}
		
		.error-message {
			display: none;
		}
		
		&.error-warning {
			
			input:not([type=checkbox]):not([type=radio]) {
				background-color: #f25a44 !important;
			}
		
			.error-message {
				
				display: block !important;
				width: 100%;
				
				color: #f25a44;
				font-size: 0.8em !important;
				text-align: right;
				font-weight: 600 !important;
				
				margin: 0;
			}
		
		}
		
		input[type=checkbox],
		input[type=radio] {
			
			width: auto;
			margin: 0;
			margin-right: 1em;
			padding: 0;
			appearance: checkbox;
			transform : scale(1.5);
		}
		
		&.euro, &.plus, &.minus {
			
			&:after, &:before {
				content: "€";
				position: absolute;
				right: 2em;
				top: 60%;
				transform: translate(-100%, -50%);
				color: #f25a44;
			}
		}
		
		&.plus, &.minus {
			&:before { 
				right: calc(40% - 1em);
			}
		}
		
		&.plus {
			&:before {
				content: "+";
			}
		}
		
		&.minus {
			&:before {
				content: "-";
			}
		}
		
		 @media only screen and (max-width: 620px) {
			 input:not([type=checkbox]):not([type=radio]), select, span {
				 width: 100%;
			 }
			 
			 &.euro, &.plus, &.minus {
				 &:after, &:before {
				 	display: none;
				 }
			 }
		 }
	}
	
	label:nth-child(even) {
		background: #F6F6F6;
	}
	
	button {
		float: inherit;
		text-decoration: none;
        color: #fff;
        background: linear-gradient(135deg,#eb3349,#f45c43);
        padding: 20px;
        font-weight: 500;
        font-size: 15px;
        padding-right: 60px;
        display: inline-block;
        position: relative;
        cursor: pointer;
        margin: 3%;
        border: none;
        position: absolute;
	    border: 0;
	    right: 0;
	    margin: 0;
	    bottom: 0;
        
        @media only screen and (max-width: 620px) {
			width: 55%;
			margin: 0;
			margin-top: 15px;
		}
		@media only screen and (max-width: 320px) {
		    bottom: 0px;
		}

        &:after{
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            height: 13px;
            width: 13px;
            right: 30px;
            border-top: 3px solid #fff;
            border-right: 3px solid #fff;
            transition: .3s ease;
        }
        
        &:hover{
        	&:after{
                right: 25px;
            }
        }

	}		
}
body.calcul div.modal .content{

    background: url(../../dist/img/modal__calcul_img.jpg) no-repeat;

}