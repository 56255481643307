.modal {
	
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2500;
	background: rgba(#000, 0.8);
	
	.content {
		
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: white;
		background: url(../../dist/img/modal_fond.jpg) no-repeat;
		background-size: cover;
		min-height: 60vh;
		width: 50%;
		padding: 2em;

		@media only screen and (max-width: 768px) {
			width: 70%;
			height: 70vh;       
		}
		@media only screen and (max-width: 411px) {
			width: 90%;
			height: 90vh;       
		}
		
		@media only screen and (max-width: 375px) {
			width: 90%;
			height: 90vh;
		}

		@media only screen and (max-width: 360px) {
			width: 90%;
			height: 90vh;
		}
		@media only screen and (max-width: 320px) {
			width: 90%;
			height: 90vh;       
		}
		a.close {           
			
			color: #FFF;
			position: absolute;
			right: 5%;
			top: 7%; 
			cursor: pointer;
			width: 35px;
			height: 35px;
			cursor: pointer;
			
			@media only screen and (max-width: 768px) and (orientation: portrait) {
				right: 0px;
			}
			@media only screen and (max-width: 411px) {
				right: 15px;
			}
			@media only screen and (max-width: 375px) {
				right: 15px;
			}

			span{
				height: 2px;
				width: 30px;
				background-color: transparent;
				display: block;
				position: absolute;
				right: 5%;
				top: 44%;
				@media only screen and (max-width: 768px) and (orientation: portrait) {
					right: 45%;
				}
				@media only screen and (max-width: 510px){
					right: 0;
				}
				&:before{
					height: 2px;
					width: 30px;
					background-color: #FFF;
					position: absolute;
					top: -10px;
					right: 0;
					content: " ";
					transition: all .2s ease-in-out;
					transform: rotate(45deg) translate(5px, 9px);


				}
				&:after{
					height: 2px;
					width: 30px;
					background-color: #FFF;
					position: absolute;
					top: 10px;
					right: 0;
					content: " ";
					transition: all .2s ease-in-out;
					transform: rotate(-45deg) translate(5px, -9px);

				}
			}
		}
		
		h3 {
			color: #ffffff;
			margin-top: 0;
			text-transform: uppercase;
			font-size: 60px;
			font-family: Raleway,sans-serif;
			font-weight: 900;
			font-style: italic;
			position: relative;
			padding-bottom: 25px;
			margin-bottom: 40px;
			margin-left: 45%;
			margin-top: 2em;
			@media only screen and (max-width: 1540px) {
				margin: 5em 0 40px 15%; 
				font-size: 50px;
			}
			@media only screen and (max-width: 1024px) and (orientation: portrait) {
				margin: 7em 0 40px 10%;
				
			}
			@media only screen and (max-width: 768px) and (orientation: portrait) {
				margin: 5.7em 0 40px 10%;
			}
			@media only screen and (max-width: 360px) {
				margin: 6.5em 0 40px 10%;   
				font-size: 50px;
			}
			@media only screen and (max-width: 320px) {
				margin: 3.5em 0 40px 10%;   
				font-size: 50px;
			}

			&:after{
					content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 3px;
				width: 90px;
				background-color: #fff;
			}
		}
		
		p {
			font-size: 1em;
			color: #FFF;
			margin-left: 45%;
			
			@media only screen and (max-width: 1540px) {
				margin: 0em 0 25px 15%; 
			}
			 @media only screen and (max-width: 1024px) and (orientation: portrait) {
				margin-left: 10%;
				width: 80%; 
			}
		}

		a{
			text-decoration: none;
			color: #FFF;
			margin-bottom: 2rem;


			&:last-child{
				margin-left: 45%;
				position: relative;


				&:after{
					content: "\f39e";
					font-family: "Font Awesome 5 Brands";
					right: -13%;
					position: absolute;
				}
				@media only screen and (max-width: 1540px) {
				margin-left:  15%;  
				}
				@media only screen and (max-width: 1024px) and (orientation: portrait) {
				margin-left: 10%;
				
				}
			}
		}
	}
}


/* MODAL GPDR */

.modal-gdpr{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 25000;
	background: rgba(0,0,0,.8);
	display: none;

	h4{
		font-size: 2rem;
		margin-top: .5rem;
		position: relative;
    	padding-bottom: 1.5rem;

		&:after{
			content: "";
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    height: 3px;
		    width: 90px;
		    background: linear-gradient(135deg,#eb3349,#f45c43);
		}

	}
	.text-intro{
		font-size: 1rem;
    	margin-bottom: 3rem;
	}
}

.modal-dialog{
	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background: #fff;
	max-height: 90vh;
	width: 75%;
	max-width: 1200px;
	padding: 2em;
	overflow-y: auto;

	 @media screen and (max-width: 1200px) {
		width: 90%;
	}

	.modal-content .modal-header{
		button.close{
		    border: none;
		    font-size: 48px;
		    color: #eb3349;
		    padding-right: 0;
		    position: absolute;
		    right: 3%;
		    top: 4%;
		    cursor: pointer;
		    z-index: 30;
		    background-color: transparent;
		    transition: .3s ease;

		    &:hover{
		    	transform: scale(0.8);
		    }
		}
	}
	.modal-footer button.btn-save{
		float: right;
		background: linear-gradient(135deg,#eb3349,#f45c43,#d8553f);
		background-size: 200% 100%;
		color: #FFF;
		border: none;
		margin-top: 4rem;
		padding: 15px 30px;
		font-family: Raleway,sans-serif;
		font-weight: 600;
    	font-family: Raleway,sans-serif;
    	font-style: italic;
    	cursor: pointer;
    	background-position: 0 0;
    	transition: .3s ease;

    	&:hover{
    		background-position: 100% 0;
    	}
	}
}
.category-container{
	margin-left: -15px;
}
.category{
	width: 25%;
	float: left;
	padding-left: 15px;
	padding-right: 15px;

	&.active{

		.category-title .select-item-btn{
			button.btn-yes{
				background: linear-gradient(135deg,#eb3349,#f45c43);
				color: #FFF;
				border: none;
			}
			button.btn-no{
				background: transparent;
				color: #333;
				border: 1px solid #ccc;
			}
		}
	}

	@media screen and (max-width: 765px) {
		width: 100%;
	}

	.select-item-btn{
		
		cursor: pointer;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 100px;
		display: flex;
		flex-wrap: wrap;
		float: right;
		width: 63px;
		margin-left: 20px;
		
		.btn{
			display: inline-block;
			margin-bottom: 0;
			font-weight: 400;
			text-align: center;
			vertical-align: middle;
			cursor: pointer;
			background-image: none;
			white-space: nowrap;
			padding: 3px 6px;
			line-height: 1.428571429;
			user-select: none;
			border: 1px solid #ccc;
			width: 50%;
			margin-left: -1px;
			font-size: 10px;
			height: 25px;
		}
		.btn-yes{

			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			margin-left: -5px;
			border-right: 1px solid transparent;
		}
		.btn-no{
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			border-left: 1px solid transparent;
			background: linear-gradient(135deg,#eb3349,#f45c43);
			color: #FFF;
			border: none;
		}
	}

	.category-title{
		margin-bottom: 2rem;
		font-size: 13px;
		p{
			font-weight: 700;
			margin-bottom: 1.5rem;
			display: inline;
		}
	}

	.category-description{
		font-size: 11px;
		text-align: justify;

		@media screen and (max-width: 765px) {
			margin-bottom: 1rem;
		}   
	}
}

.cookie-bar{
	position: fixed;
	width: 100%;
	padding: 15px;
	left: 0;
	z-index: 2500;
	background: rgba(0,0,0,.8);
	bottom: 0;

	p{
		color: #FFF;
		font-size: 16px;
		margin: 0 auto;
		text-align: center;
		margin: 10px 50px;
		@media screen and (max-width: 415px) {
			font-size: 12px;
			margin: 10px 15px;
		}
	}
	div{
		text-align: center;
		display: block;
		margin: 0 auto;
		a{
			color: #FFF;
			padding-bottom: 5px;
			font-weight: 700;
			margin-top: 10px;
			font-size: 12px;
			display: inline-block;
			
			&.accept_cookie {
				cursor: pointer;
			    border: none;
			    text-decoration: none;
			    color: #fff;
			    background: linear-gradient(135deg,#eb3349,#f45c43,#d8553f);
			    background-size: 200% 100%;
			    padding: 10px 30px;
			    font-style: italic;
			    font-weight: 600;
			    font-size: 16px;
			    display: inline-block;
			    transition: .3s ease;
			    background-position: 0 0;

		    	&:hover{
		    		background-position: 100% 0;
		    	}
			}

			&:first-child{
				margin-right: 50px;
				@media screen and (max-width: 411px) {
					margin-right: 0px;
				} 
			}
		}
	}
}
.show{
	display: block;
}
.noshow{
	display: none;
}
.clearfix::after {
	content: "";
	clear: both;
	display: table;
}