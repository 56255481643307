.cta-block.stored{
	position: fixed;
    padding: 40px 0;
    z-index: 2;
    background: #fff;
    bottom: 51px;
    right: 0;
    text-align: center;
    box-shadow: 0 0 60px 0 rgba(0,0,0,.15);
    transform: translateX(100%);
    transition: .3s ease;
    max-width: 400px;
    @media only screen and (max-width: 411px) {
        bottom: 0;
    }
    @media only screen and (max-width: 320px) {
        bottom: 79px;
    }
	&.cta-block-covid{
		bottom: 118px;
		position: fixed;
		

		.stored-calcul{
			background-image: url(../../dist/img/img_covid.svg) !important;
			background-size: 11% !important;
		}

		@media only screen and (max-width: 510px) {
			bottom: 60px;
		}
	}

    p{
    	text-align: left;
    	font-size: 20px;
    	font-weight: 600;
    	font-style: italic;
    	padding-bottom: 10px;
    	position: relative;

 
    }

    div.stored{
		
    	position: absolute;
	    bottom: 30px;
	    left: 0;
	    transform: translateX(-86%);
	    width: 390px;
	    height: 50px;
	    cursor: pointer;
	    background-color: #FFF;
	    background-image: url(../../dist/img/modal_newsletter.jpg);
	    box-shadow: 0 0 60px 0 rgba(0,0,0,.15);
	    background-size: 25%;
    	background-repeat: no-repeat;
    	background-position: 100% 70%;
    	transition: .3s ease;
		
    	a{
    		text-decoration: none;
    		color: inherit;
    	}

    	@media only screen and (max-width: 768px) {
        		transform: translateX(-100%);
       	}
    	@media only screen and (max-width: 411px) {
        	width: 300px;
        }
        @media only screen and (max-width: 320px) {
        	width: 260px;
        }


    	&:hover{
    		transform: translateX(-95%);
    		@media only screen and (max-width: 768px) {
        		transform: translateX(-100%);
        	}
    	}
		&.stored_newsletter{
			transform: translateX(-95%);
			@media only screen and (max-width: 768px) {
            	transform: translateX(-100%);
        	}
			@media only screen and (max-width: 411px) {
            	background-image: none;
        	}
			&:hover{
				background-image: url(../../dist/img/modal_newsletter-actif.jpg);
				transform: translateX(-100%);
				@media only screen and (max-width: 411px) {
            		background-image: none;
        		}
			}
		}

	    p{
	    	font-size: 16px;
	    	margin: 0;
    		padding: 1em 2em;

	    	@media only screen and (max-width: 320px){
				font-size: 14px;
			}

	    	@media only screen and (max-width: 411px) {
            	padding-left: 1.5rem;
        	}


	    	&:after{

	    	}
	    }
	}

	div.stored-taxe{
		background-image: url(../../dist/img/taxeshelter_icon.svg);
	    background-position: 28% 45%;
	    background-size: 28%;
	    width: 50px;
	    background-color: transparent;

	    @media only screen and (max-width: 1240px) {
            background-position: 28% 35%;
        }
        @media only screen and (max-width: 700px) {
            background-position: 58% 0%;
        }

		a{
			text-decoration: none;

		}
		p{
			color: #000;
		}

	}
.cta_form{
	opacity: 0;
	background-color: #FFF;
	border-top: 1px solid #f25a4433;
}
	input{
		padding: 10px 20px;
	    font-size: .9em;
	    width: 100%;
	    background: hsla(0,0%,77%,.3);
	    display: block;
	    outline: initial;
	    height: auto;
	    box-shadow: none;
	    box-sizing: inherit;
	    color: #080808;
	    margin-bottom: 1rem;
	    float: none;
	    border-radius: 0;
	}

	form {
		    text-align: left;
		    padding-top: 1rem;
		    padding-bottom: 1rem;
		    padding-left: 2rem;
		    padding-right: 2rem;
		    position: relative;
		label{
			font-size: 12px;
			float: none;
			margin-bottom: 3rem;
		    display: block;
		    width: 100%;
			span{
				text-align: left;
			}
		}
		span{
		    input{
				height: 46px;
			    width: 150px;
			    padding: 0;
			    color: transparent;
			    overflow: hidden;
			    text-decoration: none;
			    color: #fff;
			    background: linear-gradient(135deg,#eb3349,#f45c43);
			    font-style: italic;
			    font-weight: 500;
			    font-size: 20px;
			    margin: 0;
			    display: inline-block;
			    position: absolute;
			    bottom: 0;
			    text-align: left;
			    padding-left: 23px;
			    right: 0;
			    transition: .3s ease;
			    cursor: pointer;
			    border-radius: 0;
			}
			&:after{
			    content: "";
			    position: absolute;
			    transform: translateY(-50%) rotate(45deg);
			    height: 16px;
			    width: 16px;
			    right: 30px;
			    border-top: 6px solid #fff;
			    border-right: 6px solid #fff;
			    transition: .3s ease; 
			    bottom: 2%;  
			}
		}
	}
}
.cta-block.stored.stored-taxeshelter{
	top: 65px;
	background: none;
	z-index: 1000;
	position: absolute;
	
	@media only screen and (max-width: 1024px) and (orientation: portrait) {
       	right: 45px;
       	top: 7.8%;
    }
	@media only screen and (max-width: 1024px) and (orientation: landscape) {
		top: 13.4%;
		right: 45px;
	}
	@media only screen and (max-width: 768px) and (orientation: portrait) {
		top: 8.4%;
		right: 40px;
	}

	@media only screen and (max-width: 700px) {
		top: 80px;
		right: 20px;
	}

	a div.stored.stored-taxe{
		box-shadow: none;
		top: 2%;
		transform: translate(-86%, 0%);

		&:hover{
			transform: translate(-86%, -8%);
		}
		@media only screen and (max-width: 1240px){
			top: 0
		}
		@media only screen and (max-width: 700px){
			top: 15px;
    		//left: -27px;
		}
		/* @media only screen and (max-width: 510px){
			left: -20px;
		} */
	}
}
.cta-block.stored div.stored-taxe.stored-calcul{
    background-size: 8%;
    width: 250px;
    background-color: #fff;
    background-size: 7%;
    background-position: 75% 50%;
	background-image: url(../../dist/img/calculator.svg);
	bottom: 0;

	
	@media only screen and (max-width: 411px){
		width: 195px;
		background-size: 9%;
   		background-position: 85% 50%;
	}

	@media only screen and (max-width: 320px){
		width: 180px;
		height: 44px;
	}
}
.cta-block.stored.moved{
	bottom: 290px;

	@media only screen and (max-width: 768px){
		bottom: 304px;
	}
	@media only screen and (max-width: 411px){
		bottom: 330px;
	}
	@media only screen and (max-width: 325px){
		bottom: 336px;
		z-index: 10000;
	}


	.cta_form{
	    opacity: 1;
	    padding-top: 1rem;
	    position: absolute;
	
	    top: 49px;
	}
	    
	 a.stored{
	    background-size: 75%;
	    background-position: 50%;
	    background-image: url(../../dist/img/letter_open.svg);
	}
}

section.content .content-container article{
    width: calc(50% - 50px);
    float: right;
    margin-bottom: 100px;
    position: relative;
    display: flex;
    @media only screen and (max-width: 768px){
    	width: 100%;
    }
    div.content-container-block.articles{
    	width: 100%; 
    }
}
