
html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

::-webkit-scrollbar { 
	//display: none; 
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Raleway', sans-serif;
	overflow-x: hidden; 
	
	&.black {
		background: #000;
	}
}

ul.grid {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	height: 100%;
	position: fixed;
	padding: 0;
	margin: 0;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	list-style: none;

		@media only screen and (max-width: 1600px) {
			max-width: calc(100% - 400px);
		}

		@media only screen and (max-width: 1024px) {
			max-width: calc(100% - 100px);
		}

		@media only screen and (max-width: 700px) {
			max-width: calc(100% - 30px);
		}

	li {
		display: inline-block;
		height: 100%;
		width: calc(100% / 6);
		float: left;
		border-left: 1px solid rgba(grey, .15);   
	}

	&:before {
		content: '';
		width: calc(100% / 6);
		height: 100%;
		position: absolute;
		border-left: 1px solid rgba(grey, .15);  
		top: 0;
		left: calc(-100% / 6);
	} 

	&:after {
		content: '';
		width: calc(100% / 6);
		height: 100%;
		position: absolute;
		border-left: 1px solid rgba(grey, .15);
		border-right: 1px solid rgba(grey, .15);  
		top: 0;
		right: calc(-100% / 6);
	} 
} 

$skew: skewY(-7.5deg);
$counter-skew : skewY(7.5deg);

// VARIABLE

p.spacers {
	position: relative;
	height: 3000px;
}

span.cta-holder {
	display: block;
	width: 100%;
	text-align: center;

	a.cta {
		text-decoration: none;
		color: white;
		background: linear-gradient(135deg, $primary-color, $secondary-color);
		padding: 20px 40px;
		font-style: italic;
		font-weight: 500;
		font-size: 20px;
		margin-top: 15px;
		display: inline-block;
		margin: 0 auto;
		text-align: center;
		position: relative;
		padding-right: 60px;
		left:  0;
		right: 0;
		transition: .3s ease;

		@media only screen and (max-width: 375px) {
			padding: 20px 54px;
			font-size: 17px;
		}

					&:hover {
					  &:after {
						right: 25px;
					  }
	}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%) rotate(45deg);
			height: 16px;
			width: 16px;
			right: 30px;
			border-top: 6px solid white;
			border-right: 6px solid white;
			transition: .3s ease;

			
		}
	} 
} 

//
section.landing {
	height: 100%;
	height: 100vh;
	width: 100%;
	position: relative;

	@media only screen and (max-width: 1024px) {
		height: 85vh;
	}
	@media only screen and (max-width: 1024px) and (orientation: landscape){
		height: 95vh;
	}

	@media only screen and (max-width: 700px) {
		height: 100%;
		height: 90vh;
	}
	@media only screen and (max-width: 600px) {
		height: 90vh;
	}


	body.inner & {
		
		height: 85vh;

		@media only screen and (max-width: 700px) {
			height: 90vh;
		}
		@media only screen and (max-width: 600px) {
			height: 90vh;
		}
	}
	
	body.iframe & {
		height: 55vh;
		z-index: 5;
	}


	div.landing-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: $skew;
		transform-origin: top left;
		overflow: hidden;

		div.landing-bg-img {
			transform: $counter-skew;
			transform-origin: top left;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-image: url('../img/iStock-697047716.jpg');
			background-size: cover;
			background-position: 50% 0%;
			background-repeat: no-repeat;
			background-attachment: fixed;
			mix-blend-mode: multiply;

			@media only screen and (max-width: 510px) {
				background-size: auto 110%;
				background-attachment: initial;
			}
		}
		div.landing-bg-img-contact{
			background-image: url('../img/contact.jpg');

		}
		div.landing-bg-img-filmography{
			background-image: url('../img/filmography.jpg');
		}
		div.landing-bg-img-blog{
			background-image: url('../img/actu.jpg');
		}
		div.landing-bg-img-job{
			background-image: url('../img/job.jpg');
		}
		div.landing-bg-img-actu{
			background-image: url('../img/actu_big.jpg');
		}
	}

	div.landing-bg-content {
			position: absolute;
			top: 20%;
			left: 0;
			right: 0;
			margin: 0 auto;
			color: #fff;
			max-width: 1200px;
			.btn_back{
				color: #FFF;
			    text-decoration: none;
			    position: relative;
			    -webkit-transition: .2s;
			    transition: .2s;

			    display: block;
				&:before{
					content: "";
					position: absolute;
					left: -15px;
					display: inline-block;
					top: 45%;
					width: 7px;
					height: 7px;
					transform: rotate(45deg) translateX(-50%);
					border-style: solid;
					border-width: 1px;
					border-color: transparent;
					border-bottom-color: #FFF;
					border-left-color: #FFF;
					transition: .2s ease-in;
				}

				&:hover{
					color: #f45c43;
					&:before{
						border-bottom-color: #f45c43;
						border-left-color: #f45c43;
						left: -20px;			
					}
				}
			}
			
		@media only screen and (max-width: 411px) {
			top: 40%;
		}
		@media only screen and (max-width: 375px) {
			top: 35%;
		}
		@media only screen and (max-width: 320px) {
			top: 36%;
		}

		a.inner-cta{
			text-decoration: none;
			color: #fff;
			background: linear-gradient(135deg,#eb3349,#f45c43);
			padding: 20px 20px;
			font-style: italic;
			font-weight: 600;
			font-size: 15px;
			margin-top: 15px;
			padding-right: 60px;
			display: inline-block;
			position: relative;

			&:after{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%) rotate(45deg);
				height: 13px;
				width: 13px;
				right: 30px;
				border-top: 3px solid #fff;
				border-right: 3px solid #fff;
				transition: .3s ease;
			}
			 &:hover{
				 &:after{
					right: 25px;
				}
			}
		}

		@media only screen and (max-width: 1600px) {
			max-width: calc(100% - 400px);
		}

		@media only screen and (max-width: 1024px) and (orientation: landscape) {
			margin-left: 65px;   
		}

		@media only screen and (max-width: 1024px) {
			max-width: calc(100% - 90px);

		}

		@media only screen and (max-width: 700px) {
			max-width: calc(100% - 55px);
		}

		@media only screen and (max-width: 450px) {
			max-width: calc(100% - 35px);
		}

		h1 {
			text-transform: uppercase;
			font-size: 90px;
			font-family: 'Raleway', sans-serif;
			font-weight: 900;
			font-style: italic;
			position: relative;
			padding-bottom: 25px;
			margin-bottom: 40px;
			@media only screen and (max-width: 1400px) {
					font-size: 75px;
				}

				@media only screen and (max-width: 700px) {
					font-size: 50px;
				}
				@media only screen and (max-width: 411px) {
					font-size: 30px;
					margin-top: 0px;
					margin-bottom: 20px;
				}
				@media only screen and (max-width: 375px) {
					font-size: 30px;
					margin-top: 0px;
					margin-bottom: 20px;
				}
				@media only screen and (max-width: 320px) {
					font-size: 20px;
					margin-top: 0px;
					margin-bottom: 20px;
				}

			body.inner & {
				font-size: 50px;
				

				@media only screen and (max-width: 1400px) {
					font-size: 40px;
				}

				@media only screen and (max-width: 700px) {
					font-size: 50px;
				}
				@media only screen and (max-width: 411px) {
					font-size: 30px;
					margin-top: 0px;
					margin-bottom: 20px;
				}
				@media only screen and (max-width: 375px) {
					font-size: 30px;
					margin-top: 0px;
					margin-bottom: 20px;
				}
				@media only screen and (max-width: 320px) {
					font-size: 20px;
				}
			}

		   

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				height: 3px;
				width: 90px;
				background-color: white;
			}
		}

		p:not(.text-promotions) {
			font-weight: 300;
			font-size: 30px;
			max-width: 700px;
			margin-top: 0;

			body.inner & {
				font-size: 25px;

				@media only screen and (max-width: 768px) {
					max-width: 600px;
				}
				@media only screen and (max-width: 411px) {
					font-size: 18px;
				}
				@media only screen and (max-width: 375px) {
					font-size: 18px;
					max-width: 300px;
				}
				@media only screen and (max-width: 320px) {
					font-size: 18px;
					max-width: 245px;
				}
			}

			@media only screen and (max-width: 1280px) {
			font-size: 25px;
			max-width: 700px;
			}
			@media only screen and (max-width: 978px) {
				max-width: 90%;
			}
			@media only screen and (max-width: 414px) {
				font-size: 19px;
    			max-width: 251px;
			}
			@media only screen and (max-width: 320px) {
				font-size: 18px;
				max-width: 255px;
			}

		}
	}
}

.iframe-container {

	overflow: hidden;
	//padding-top: 56.25%;
	
	height: 100vh;
	position: relative;
	
	&.offset {
		margin-top: 50px;
	}

	
	iframe {
	   border: 0;
	   height: 100%;
	   left: 50%;
	   transform: translateX(-50%);
	   position: absolute;
	   top: 0;
	   width: 100%;
	   min-width: 1200px;
	}
	
}

a.button {
	display: inline-block;
	color: white;
	text-decoration: none;
	box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);
	background: linear-gradient(135deg, $primary-color, $secondary-color);
	position: relative;
	transition: .2s ease;
	padding: 20px 40px;
	font-style: italic;
	font-weight: 600;
	font-size: 20px;
	margin-top: 15px;
	padding-right: 60px;
	
	&:before {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: linear-gradient(-45deg, $primary-color, $secondary-color);
	  z-index: -1;
	  opacity: 1;
	  transition: .3s ease;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
		height: 16px;
		width: 16px;
		right: 30px;
		border-top: 6px solid white;
		border-right: 6px solid white;
		transition: .2s ease;
	}
	
	&:hover {
	  box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.15);
	  //margin-top: 17px;
	  &:before {
		opacity: 1;
	  }
	  &:after {
		right: 25px;
	  }
	}
}
// Social 

ul.social {
	position: absolute;
	bottom: 65px;
	left: 65px;
	color: white;
	list-style: none;
	padding: 0;
	margin: 0;
	
	@media only screen and (max-width: 1024px)  {
		bottom: 250px;
		right: 65px;
		left: initial;
		text-align: right;
	}
	@media only screen and (max-width: 978px) {
		bottom: 200px;
		bottom: 250px;
		right: 65px;
		left: initial;
		text-align: right;
	}
	@media only screen and (max-width: 768px) {
		bottom: 200px;
	}
	@media only screen and (max-width: 1024px) and (orientation: landscape) {
	   right: 65px;
	   bottom: 200px;
	}

	@media only screen and (max-width: 700px) {
		right: 15px;
		bottom: 140px;
	}
	@media only screen and (max-width: 414px) {
		bottom: 100px;
		right: 25px;
	}
	@media only screen and (max-width: 320px) {
		bottom: 60px;
	}

	li {
		//text-align: center;
		padding: 10px 0px;
		position: relative;

		&:first-child{
			padding-bottom: 2.6rem;
			@media only screen and (max-width: 1024px)  {
				left: 5px;
			}
		}

		&:nth-child(2){
			// &:before {
			// 	position: absolute;
			// 	content: '';
			// 	top: -15px;
			// 	height: 2px;
			// 	background: white;
			// 	width: 30px;
			// 	left: 0;
			// } 
		}
		@media only screen and (max-width: 375px) {
			padding-bottom: 10px;
		}
		
		ul.languages {
			margin: 0;
		    padding: 0;
		    position: initial;
		    height: 30px;
		    overflow: hidden;
		    transition:1s;

		    &:after {
				position: absolute;
				content: '';
				bottom: 25px;
				height: 2px;
				background: white;
				width: 100%;
				left: 0;
				@media only screen and (max-width: 1024px) {
					right: 0;
					left: inherit;
				}
			} 
		    &:hover{
		    	//height: 175px;
		    	height: 125px;
				
				z-index: 10000;

				@media only screen and (max-width: 414px) {
					height: 110px;
				}
			}

			
				@media only screen and (max-width: 414px) {
					height: 110px;
					z-index: 10000;
				}
			

			li {				
				margin: 0;
				padding: 0;
				list-style: none;
				display: block;
				top: 0;
				opacity: .5;
				position: inherit;
				padding-bottom: 1rem;

				@media only screen and (max-width: 414px) {
					font-size: 14px;
				}

				&:hover{
					opacity: 1;
					transition:.5s;
				}
				&:active{
					@media only screen and (max-width: 414px) {
						opacity: 1;
						transition:.5s;
					}
				}

				&:first-child{
					text-transform: uppercase;
					text-decoration: none;
				    color: inherit;
				    font-size: 25px;
				    opacity: 1;
				    @media only screen and (max-width: 414px) {
						font-size: 14px;
					}
				}

				@for $i from 2 through 4 {
					&:nth-child(#{$i}) {
						left: (30px * $i ) - 30px;
					}	   
				}


				
				a {
					text-transform: uppercase;
				}
				
				&:after {
					display: none; 
				}
			}

			
		}

		a {
			text-decoration: none;
			color: inherit;
			font-size: 25px;

			@media only screen and (max-width: 414px) {
				font-size: 14px;
			}
		}
	}
}

// Separator

div.separator {
	width: 100%;
	height: 550px;
	background: linear-gradient(135deg, $primary-color, $secondary-color);
	transform: $skew;
	transform-origin: top left;
	overflow: hidden;
	position: relative;
	margin-top: 300px;

	&.special {
		position: absolute;
	}

	&.special, &.topper {
		//margin-top: 1000px; 
	}
	
	&.last {
		margin-top: -250px;	    
		z-index: -1;
	}
	
	div.separator-content {
		width: 100%;
		height: calc(100% + 550px);
		position: absolute;
		top: 50%;
		
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		//background-attachment: fixed;
		transform: $counter-skew translateY(-50%);
		//transform-origin: top left;
	}
}

// WARNING

p.warning {
	max-width: 1200px;
	margin: 0 auto;
	font-weight: 300;
	font-size: 15px;
	line-height: 20px;
	color: rgba(black, .5);
	position: relative;

	@media only screen and (max-width: 1600px) {
		max-width: calc(100% - 400px);
	}

	@media only screen and (max-width: 1024px) {
		max-width: calc(100% - 100px);
	}
}
// Section content

section.content {
	margin-bottom: 100px;
	//overflow: auto;
	&.content_filmography{
		margin-top: -120px;
	@media only screen and (max-width: 320px) {
		margin-top: -30px;
	}
   }
	body.filmographie & {
		margin-bottom: 50px;
	}

	&:after {
		content: "";
		display: table;
		clear: both;
	}


	div.content-container {
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		&.articles-homepage h2{	
			width: calc(45% - 75px);
			float: right;
			margin-top: 75px;
			position: relative;
		}

		&.content-container_job{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		@media only screen and (max-width: 1600px) {
			max-width: calc(100% - 400px);
		}

		@media only screen and (max-width: 1024px) {
			max-width: calc(100% - 100px);
		}

		@media only screen and (max-width: 700px) {
			max-width: calc(100% - 30px);
		}

		
		
		&:not(.actu) {
			.calcul_text{
				margin-bottom: 20px;
				&:nth-child(3){
					margin-bottom: 50px;
				}
			}
		   h2 {
				font-size: 50px;
				font-weight: 900;
				position: relative;
				padding-bottom: 30px;
				margin-bottom: 45px;
				@media only screen and (max-width: 700px) {
					font-size: 28px;
				}
		
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					height: 3px;
					width: 90px;
					background: linear-gradient(135deg, $primary-color, $secondary-color);
				}
			}
	
			& > p {
				font-weight: 300;
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 100px;
			} 
		}

		&.actu {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			

			h2 {
				color: white;
				width: 100%;

				&:after {
					background: white;
				}
			}
		}

		 div.content-container-search {
			box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.15);
			background: #f5f5f5;
			padding: 80px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;



			@media only screen and (max-width: 768px) {
				padding: 40px;
			}
			
			div {
				
				/*&:nth-child(1){
					//width: 50%;

					@media only screen and (max-width: 561px) {
						width: 100%;
					} 
				}*/
				
				&.large {
					width: 100%;
				}
				
				&.medium {
					width: 48%;
				}
				
				// float: left;
				
				/*&:first-of-type {
					padding-right: 50px;
				}*/
				&.filmography_implication{
					div{
					   width: 100%;
						display: block;
						margin-top: 1.5rem;
						text-align: right;
						position: absolute;
						right: 7%;
						@media only screen and (max-width: 1540px) {
							position: inherit;
							margin-top: 5rem;
							text-align: left;

							label:nth-child(1){
								margin-left: 0;
							}
						}
						
						@media only screen and (max-width: 1200px) {
							   display: flex;
								flex-wrap: wrap;
								justify-content: space-between;
						}


						label{
								padding-left: 2rem;
								position: relative;
								margin-bottom: 12px;
								cursor: pointer;
								-webkit-user-select: none;
								-moz-user-select: none;
								-ms-user-select: none;
								user-select: none;
								margin-left: 2rem;
								@media only screen and (max-width: 1250px) {
									font-size: 14px;
								}
								@media only screen and (max-width: 1200px) {
									display: inline-block;
									margin-top: 1rem;
									margin-left: 0rem;
									margin-right: 2rem;


									&:nth-child(3){
										margin-left: 0;
									}
								}

							 input {
								position: absolute;
								opacity: 0;
								cursor: pointer;
							}

							.checkmark {
								position: absolute;
								top: 0;
								left: 0;
								height: 20px;
								width: 20px;
								background-color: #eee;

								&.checkbox_check{
									background-image: linear-gradient(to right, #f24f46 , #F35346);
								}
							}

							.container:hover input ~ .checkmark {
								background-color: #ccc;
							}


							 input:checked ~ .checkmark {
								background-image: linear-gradient(to right, #f24f46 , #F35346);
							}

							.checkmark:after {
								content: "";
								position: absolute;
								display: none;
							}

							 input:checked ~ .checkmark:after {
								display: block;
							}
						}
					}
				}
				h3 {
					margin-top: 0;
					font-weight: 600;
					font-style: italic;
					font-size: 24px;

					 @media only screen and (max-width: 768px) {
						white-space: initial;
						font-size: 20px;
					}

				}

				p {
					float: left;
					margin-top: 20px;
					font-weight: 600;
					font-style: italic;
					color: rgba(black, .75);
					font-size: 20px;
					margin-right: 20px;
				}

				label {
					color: rgba(black, .75);
					font-size: 16px;
					font-weight: 300;
					position: relative;
					top: 2px;
				}


				select {
					width: 100%;
					border: 1px solid rgba(grey, .2);
					background: white;
					padding: 15px 30px;
					height: 50px;
					//float: left;
					margin-bottom: 20px;
					font-family: Raleway,sans-serif;
					font-weight: 300;
					display: block;
					border-radius: 0;
					@media only screen and (max-width: 768px) {
						padding: 10px 40px;
					}
				}

				input[type="text"] {
					width: 100%;
					border: 1px solid rgba(grey, .2);
					padding: 15px 30px;
					float: left;
					margin-bottom: 20px;
					font-family: Raleway,sans-serif;
					font-weight: 300;
				}

				input[type="radio"] { 
					margin-top: 20px;
					margin-left: 10px;
					margin-right: 10px;
				}

				/*&:nth-child(2) {
					float: none;
					clear: both;
					padding: 0;
					width: 40%;
					display: flex;
					flex-wrap: wrap;

					@media only screen and (max-width: 561px) {
					   width: 100%;
					   display: block;
					}

					p{
						float: none;
					}

				}*/
			}
		}

		div.content-container-block {
			box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.15);
			background: #f5f5f5;

			a{
				text-decoration: none;
				color: $secondary-color;
			}

			.button_link  {
					text-decoration: none;
					color: white;
					background: linear-gradient(135deg, $primary-color, $secondary-color);
					padding: 20px 40px;
					font-style: italic;
					font-weight: 500;
					font-size: 20px;
					margin-top: 15px;
					display: inline-block;
					position: absolute;
					bottom: 0;
					right: 0;
					padding-right: 60px;
					transition: .3s ease;
					//box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.3);

					&.notext, &.small {
						height: 63px;
						width: 63px;
						padding: 0;
						transition: .3s ease;
						
						color: transparent;
						overflow: hidden;
						text-indent: -100vw;
						
						&:after {
							right: 25px;
						}

						&:hover {
							&:after {
								right: 20px; 
							}
						}
					}

					&:hover {
					  &:after {
						right: 25px;
					  }
				  }

					&:after {
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%) rotate(45deg);
						height: 16px;
						width: 16px;
						right: 30px;
						border-top: 6px solid white;
						border-right: 6px solid white;
						transition: .3s ease;

					}
				}


			&.small {
				width: calc(45% - 75px);
				float: right;
				margin-top: 75px;
				position: relative;

				&.blog_actu-home{
					box-shadow: none;
					background: none;
					margin-top: 75px;
					
					&:nth-child(2){
						background-color: red;
						margin-top: 150px;

						.content-container-block-img{
							margin-top: -75px;
						}
					}

					h2{
						@media only screen and (max-width: 1024px) {
						margin-top: 4em;
						}
					}
					.content-container-block{
						box-shadow: 0 0 60px 0 rgba(0,0,0,.15);
						background: #f5f5f5;
						//margin-top: 75px;

						.link_news-active{
							margin-top: 0;
						}
						.cta_all-blog{
							position: absolute;
							bottom: 20px;
							left: 50px;
							font-size: 12px;
							color: #eb3349;
							@media only screen and (max-width: 1300px) {
								max-width: 100px;
							}
							@media only screen and (max-width: 1280px) {
								font-size: 10px;
							}
							@media only screen and (max-width: 1024px) {
								font-size: 12px;
								max-width: 100%;
							}
						}
					}
				}
				div.content-container-block-img {
					height: 200px;
					img{
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}

				div.content-container-block-txt {
					padding: 50px;
					margin-top: -90px;


					
					@media only screen and (max-width: 375px) {
						padding: 20px;
						padding-bottom: 80px;
					}

				}

				@media only screen and (max-width: 1024px) {
					width: 100%;
					float: none;
				}
			}
			
			&.grid{
				.landing-bg-img-job{
					background-image: url('../img/job_small.jpg');
				}
				.landing-bg-img-job-coo{
					background-image: url('../img/job_production.jpg');

				}
				.landing-bg-img-job-ic{
					background-image: url('../img/job_ic.jpg');
				}
					.job a{
						color: #eb3349;
						position: inherit;
						background: 0;
						display: inline-block;
						margin-bottom: 2rem;
						margin-right: 5rem;
						
							&:after{
								border-top: 2px solid #eb3349;
								border-right: 2px solid #eb3349;
								height: 9px;
								width: 9px;
								position: absolute;
								content: "";
								transform: translateY(-50%) rotate(45deg);
								top: 50%;
								right: -29px;
							}
					}
			}
			&.medium {
				width: 55%;
				float: left;
				position: relative;
				
				&.filmography_medium{
					width: 100%;

					.landing-bg-img-job{
						background-image: url('../img/filmography_wide.jpg');
						background-position: 0% 20%;

						&:after{
								height: 160px;
						}
					}
				}
				div.content-container-block-img {
					height: 300px;
					img{
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
				

				@media only screen and (max-width: 1024px) {
					width: 100%;
					float: none;
				}
			}

			&.video {
				height: auto;
				margin-bottom: 75px;

				iframe {
					min-height: 370px;
					display: block;

					@media only screen and (max-width: 1400px) {
						height: 320px;
					}
				}
			}

			&.wide {
				width: 100%;
				float: none;



				&.video {
					height: auto;
					margin-bottom: 75px;

					iframe {
						min-height: 670px;
						display: block;

						@media only screen and (max-width: 1400px) {
							height: 520px;
						}
					}
				}

				div.content-container-block-img {
					display: none;

					 body.filmographie & {
						display: block;
						height: 250px;

						&:after {
							height: 80px;
							transform: skewY(3.5deg);
						}
					 }
				}

				div.content-container-block-txt {
					padding: 80px;
					margin-top: 0;

					&.block-txt_article{

						ul li{
							font-weight: 300;
							font-size: 20px;
							line-height: 1.6em;
						}
						blockquote{
							margin: 0;
							padding-left: 4em;
							position: relative;
							@media only screen and (max-width: 511px) {
								padding-left: 2em;
							}
							&:after{
								content: "";
								position: absolute;
								left: 0;
								top: 0;
								background: url(../../dist/img/blockquote_icon.svg) no-repeat;
								height: 50px;
								width: 40px;
								background-size: 60%;
								@media only screen and (max-width: 511px) {
									height: 40px;
									width: 30px;
									background-size: 60%;
								}
							}

							p{
								line-height: 1.7em;
								
								margin: 0;
								span{
									margin: 0;
								}
								strong{
									font-weight: 600;
									font-size: 21px;
									font-style: italic;
									@media only screen and (max-width: 511px) {
										font-size: 17px;
									}
								}
							}
						}

						hr{
							border: 0.5px solid #eb3349;
							opacity: .5;
						}
					}
					blockquote p{
						font-size: 13px;
					}
					&.implication_scope{
						p{
							font-style: italic;
						}
						div{
							margin-bottom: 40px;
							&:first-of-type{
								margin-top: 65px;
							}
							p:nth-child(1){
								color: #EC3649;
								font-size: 20px;
								font-weight: 600;
								font-style: normal;
								
							}
						}
					}

					div.movie_details {
						columns:3;
						margin: 0 auto;
						column-gap: 120px;
						margin-top: 4rem;
						width: 90%;
						display: flex;
						flex-wrap: wrap;


						div.block-infos{
							width: 33%;
							display: inline-block;
							margin-bottom: 2rem;
							position: relative;

							@media only screen and (max-width: 411px) {
								width: 55%;
								margin: 0 auto;
							}

							&:before{
								content: "";
								font-size: 23px;
								width: 37px;
								background: url(../../dist/img/genre_icon.svg) no-repeat;
								display: block;
								height: 100px;
								position: absolute;
								left: -22%;
								top: 0;
								height: 40px;
								background-size: 70%;

								@media only screen and (max-width: 411px) {
									left: -39%;
								}

								}


							ul{
								list-style: none;
								padding-left: 0;
								li{
									font-weight: 300;
									font-size: 16px;
									line-height: 28px;
									 @media only screen and (max-width: 512px) {
										font-size: 14px;
									}
								}
							}
							h3{
								font-size: 23px;
								width: 210px;
								position: relative;
							}
							 &.block-infos-realisateur{
								&:before{
									background: url(../../dist/img/realisateur_icon.svg) no-repeat;
									background-size: 80%;
								   
								}
							}
	   
							&.block-infos-actor{
								&:before{
   
									background: url(../../dist/img/actor_icon.svg) no-repeat;
									background-size:80%;

								}
							}
							&.block-infos-financement{
								&:before{

									background: url(../../dist/img/piliers_icon.svg) no-repeat;
									background-size: 100%;

								}
							}
							&.block-infos-formats{
								&:before{

									background: url(../../dist/img/format_icon.svg) no-repeat;
									background-size: 90%;

								}
							}
							&.block-infos-budget{
								&:before{

									background: url(../../dist/img/budget_icon.svg) no-repeat;
									background-size: 60%;

								}
							}
							&.block-infos-year{
								&:before{
									background: url(../../dist/img/year_icon.svg) no-repeat;
									background-size: 80%;

								}
							}
						}
					}  

					p img{
						height: auto;
					}

					@media only screen and (max-width: 1400px) {
						padding: 60px;
					}
					@media only screen and (max-width: 768px) {
						padding: 30px;
					}
					@media only screen and (max-width: 375px) {
						padding: 30px;
						padding-bottom: 5rem;
					}
					table tbody tr td p {
						margin-left: 30px;
						@media only screen and (max-width: 411px) {
							font-size: 10px;
							margin-left: 0.5rem;
						}
						@media only screen and (max-width: 320px) {
							font-size: 10px;
							margin-left: 0.2rem;
						}
					   
					}

					 body.filmographie & {
						padding-top: 0;
					 }
				}
			}

			&.articles, &.actu, &.enterprise, &.grid {
				width: calc(50% - 50px);
				float: right;
				margin-bottom: 100px;
				position: relative;
				height: fit-content;
				
				&.jobs_except{
					float: left;
					height: fit-content;
				}

				div.contact-team-management {
					background-image: url('../img/team_management.png');
				}
				div.contact-team-investor {
					background-image: url('../img/team_investor.png');
				}

				&:nth-child(2n) {
					float: left;
				}

				div.content-container-block-img {
					height: 300px;

					&.content-container-block-img-active{
						display: block;
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						
						@at-root {
							.no-object-fit & {
								display: none !important;
							}
						}
					}
				}

				div.content-container-block-txt {
					//padding: 80px;
					margin-top: -20px;
				}

				@media only screen and (max-width: 1024px) {
					width: calc(50% - 15px);
					margin-bottom: 30px;
				}

				@media only screen and (max-width: 700px) {
					width: 100%;
				}


			}

		   &.enterprise, &.grid {

				h3 {
					text-transform: uppercase;
					font-weight: 600;
					font-style: initial;
				}
			}

			&.actu {


				div.content-container-block-img {
					display: none;
				}

				div.content-container-block-txt {
					padding: 40px;
					margin-top: 0;

					h3 {
						font-weight: 600;
						font-style: initial;
						font-size: 25px;
						margin-bottom: 10px;
					}
				}
			}

			&.big {
				width: 100%;
			}

			div.content-container-block-img {
				width: 100%;
				background: linear-gradient(135deg, $primary-color, $secondary-color);
				position: relative;
				overflow: hidden;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				
				&:after {
					content: '';
					position: absolute;
					height: 120px;
					width: 100%;
					transform: $counter-skew;
					transform-origin: top left;
					overflow: hidden;
					bottom: 0;
					right: 0;
					background: #f5f5f5;
				}
			}

			div.content-container-block-txt {
				padding: 80px;
				padding-top: 0;
				position: relative;
				margin-top: -30px;

				img {
					width: 100%;
				} 

				@media only screen and (max-width: 1400px) {
					padding: 60px;
					padding-top: 0;
				}
				@media only screen and (max-width: 375px) {
					padding: 25px;
					padding-top: 0;
					padding-bottom: 80px;
				}

				span {
					margin-bottom: 20px;
					display: block;

					@media only screen and (max-width: 375px) {
						margin-bottom: 5px;
					}

					img {
						width: 70px;
					}
				}
				h2,h3 {
					margin-top: 0;
					font-weight: 600;
					font-style: italic;
					font-size: 28px;
					color: #000;

					@media only screen and (max-width: 768px) {
						font-size: 25px;
					}
				}
				&.job h2{
					font-size: 24px;
				}
				p {
					font-weight: 300;
					font-size: 20px;
					line-height: 28px;


					@media only screen and (max-width: 1440px) {
						font-size: 18px;
						line-height: 26px;
					}

					@media only screen and (max-width: 1280px) {
						font-size: 16px;
						line-height: 26px;
					}
					&.last_update{
						position: absolute;
						bottom: 10px;
						left: 80px;
						font-size: 13px;
						color: #eb3349;
						display: none;
						span{
							display: inline-block;
						}

						@media only screen and (max-width: 1280px) {
							font-size: 13px ;
						}
						@media only screen and (max-width: 1260px) {
							font-size: 13px ;
							max-width: inherit ;
						}
						@media only screen and (max-width: 760px) {
							bottom: -10px;
							left: 30px;
						}

						@media only screen and (max-width: 520px) {
							left: 30px;
							bottom: 10px;
						}
						
					}
				}

				.button_link  {
					text-decoration: none;
					color: white;
					background: linear-gradient(135deg, $primary-color, $secondary-color);
					padding: 20px 40px;
					font-style: italic;
					font-weight: 500;
					font-size: 20px;
					margin-top: 15px;
					display: inline-block;
					position: absolute;
					bottom: 0;
					right: 0;
					padding-right: 60px;
					transition: .3s ease;
					//box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.3);

					&.notext, &.small {
						height: 63px;
						width: 63px;
						padding: 0;
						transition: .3s ease;
						
						color: transparent;
						overflow: hidden;
						text-indent: -100vw;
						
						&:after {
							right: 25px;
						}

						&:hover {
							&:after {
								right: 20px; 
							}
						}
					}

					&:hover {
					  &:after {
						right: 25px;
					  }
				  }

					&:after {
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%) rotate(45deg);
						height: 16px;
						width: 16px;
						right: 30px;
						border-top: 6px solid white;
						border-right: 6px solid white;
						transition: .3s ease;

					}
				}
			}
		}
	}
}

section.content {
	margin-top: -120px;
	
		@media only screen and (max-width: 768px) {
			margin-top: -90px;
		}
		@media only screen and (max-width: 320px) {
			margin-top: -30px;
		}
	& ~ section.content {
		margin-top: 0;
		
		&.topper {
		  margin-top: -400px;	    
		}
	}
}

// FORM


form {

	@media only screen and (max-width: 375px) {
		padding-bottom: 5rem;
	}

	input {
		border:none;
		background: #F1F1F1;
		padding: 20px 40px;
		width: calc(50% - 50px);
		float: left;
		margin-bottom: 50px;
		font-family: 'Raleway', sans-serif;
		font-weight: 300;

		 @media only screen and (max-width: 768px) {
			width: calc(50% - 15px);
		}
		@media only screen and (max-width: 375px) {
			float: inherit;
		}

		&:nth-child(3n) {
			float: right;
		}

		@media only screen and (max-width: 1024px) {
			width: calc(50% - 15px);

		}

		@media only screen and (max-width: 768px) {
			margin-bottom: 20px;
		}

		@media only screen and (max-width: 375px) {
			width: 100%;
		}

	}

	input[type="checkbox"] {
		float: left;
		clear: both;
		width: auto;
		margin-right: 10px;
		padding: 10px;

		@media only screen and (max-width: 768px) {
			margin-top: 2rem;
		}
		@media only screen and (max-width: 375px) {
			float: inherit;
			margin-bottom: 0;
		}
	}

	label {
		float: left;
		width: calc(50% - 50px);
		font-size: 14px;
		@media only screen and (max-width: 768px) {
			width: calc(50% -  -150px);
			font-size: 14px;
			margin-top: 2rem;
		}
		@media only screen and (max-width: 375px) {
			width: 90%;
			float: inherit;
		}
	}

	a, button {
		float: left;
		cursor: pointer;
		border: none;
		margin-left: 100px;
		text-decoration: none;
		color: white;
		background: linear-gradient(135deg, $primary-color, $secondary-color);
		padding: 18px 40px;
		font-style: italic;
		font-weight: 500;
		font-size: 20px;
		display: inline-block;
		position: relative;
		padding-right: 60px;
		transition: .3s ease;
		

		@media only screen and (max-width: 768px) {
			margin-left: 30px;
		}
		@media only screen and (max-width: 375px) {
			position: absolute;
			bottom: 0;
			right: 0;
		}

					&:hover {
					  &:after {
						right: 25px;
					  }
				  }


		&:after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%) rotate(45deg);
			height: 16px;
			width: 16px;
			right: 30px;
			border-top: 6px solid white;
			border-right: 6px solid white;
			transition: .3s ease;
		}
	} 
} 

 .news{
		section.content div.content-container{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}


p.rtecenter{
	text-align: center;
	display: block;

}

a.link_newsletter{
	background: transparent;
    color: red;
    float: none;
    display: block;
    margin: 0;
    font-size: inherit;
    padding: 0;
    color: #000;
    font-weight: 600;
    font-style: inherit;
    display: inline;
    position: inherit;

    &:after{
    	display: none;
    }
}

.m-pagetransition {
    visibility: hidden;
}

@media only screen and (max-width: 460px) {
	.slick-dots li button:before{
		display: none;
	}
}


.promotions{
	margin: 0 auto;
	max-width: 1200px;
	@media only screen and (max-width: 1600px) {
		max-width: calc(100% - 400px);
	}

	@media only screen and (max-width: 1024px) {
		max-width: calc(100% - 100px);
	}

	@media only screen and (max-width: 700px) {
		max-width: calc(100% - 30px);
	}

	p{
	position: absolute;
	bottom: 120px;
	color: white;
	font-weight: 400;
	font-size: 1.1em;


		@media only screen and (max-width: 700px) {
			bottom: 90px;
			font-size: .9em;
		}
	}
}


.msg_covid{
	background: rgba(0, 0, 0, 0.496);
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	div{
		background-color: #fff;
		padding: 70px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50% , -50%);
		min-width: 70vw;
		max-width: 1300px;
		@media only screen and (max-width: 1300px) {
			min-width: 85vw;
		}
		@media only screen and (max-width: 900px) {
			min-width: 95vw;
			padding: 30px;
			overflow: scroll;
			height: 90vh;
		}
		@media only screen and (max-width: 510px) {
			padding-top: 70px;
			width: 95vw;
		}


		.close_modal-covid{
			height: 30px;
			width: 30px;
			background-color: transparent;
			display: block;
			position: absolute;
			right: 30px;
			top: 30px;
			cursor: pointer;
			@media only screen and (max-width: 510px) {
				right: 30px;
				top: 30px;
			}
			&:before{
				height: 2px;
				width: 30px;
				background-color: #f45c43;
				position: absolute;
				top: 0;
				right: 0;
				content: " ";
				transition: all .2s ease-in-out;
				transform: rotate(45deg) translate(5px, 9px);


			}
			&:after{
				height: 2px;
				width: 30px;
				background-color: #f45c43;
				position: absolute;
				top: 20px;
				right: 0;
				content: " ";
				transition: all .2s ease-in-out;
				transform: rotate(-45deg) translate(5px, -9px);

			}
		}
		h4{
			font-size: 30px;
			font-weight: 900;
			position: relative;
			margin: 0;
			padding-bottom: 30px;
			margin-bottom: 45px;
			
			@media only screen and (max-width: 1300px) {
				max-width: 80%; 
			}
			@media only screen and (max-width: 510px) {
				font-size: 20px;
				max-width: 80%;
				padding-bottom: 20px;
			}

			&:after{
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 3px;
				width: 90px;
				background: linear-gradient(135deg,#eb3349,#f45c43);

				@media only screen and (max-width: 510px) {
					width: 70px;
				}

			}
		}
		p{
			font-size: 14px;

			span{
				
				font-weight: 600;
			}

			a{
				font-weight: 600;
				color: #f45c43;
				text-decoration: none;
			}
		}
	}
}



.last_update{
	position: absolute;
	bottom: 10px;
	left: 80px;
	font-size: 13px;
	color: #eb3349;
	@media only screen and (max-width: 1360px) {
		max-width: 160px;
		left: 60px;
	}
	@media only screen and (max-width: 1280px) {
		left: 60px;
		font-size: 11px;
	}
	@media only screen and (max-width: 1260px) {
		left: 60px;
		font-size: 11px;
		max-width: 160px;
	}
	@media only screen and (max-width: 520px) {
		left: 30px;
	}
}

section.content:nth-last-of-type(2){
	div.content-container div.content-container-block div.content-container-block-txt p.last_update{
		display: block;
	}
}

.link_news-active{
	margin-top: 2em;
}








