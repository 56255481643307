footer {
	height: auto;
	overflow: hidden;
	
	position: relative;
	
	/*@media only screen and (min-width: 1680px) {
		height: 32vw;
	}
	@media only screen and (min-width: 1400px) {
		height: 35vw;
	}

	@media only screen and (max-width: 768px) {
		height: 200px;
	}*/

	ul.social {
	    position: absolute;
	    bottom: 45px;
	    right: 2.4%;
    	text-align: right;

	    li {
	        display: inline-block;
	        padding: 10px 20px;

	        &:first-child{
    			padding: 10px 20px;
    		}

	        ul.languages{
	        	height: auto;
    			overflow: auto;

    			li{
    				display: inline-block;
    				margin-left: 20px;
    				padding: 0;

    				&:first-child{
    					font-size: 18px;
    				}
    			}

    			&:after{
    				display: none;
    			}
    			&:hover{
    				height: auto;
    			}

	        }

	        a {
	            text-decoration: none;
	            color: inherit;
	            font-size: 18px
	        }
	    }
	}

	div.footer-container {
        position: relative;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #eb3349, #f45c43);
        transform: $skew;
        transform-origin: bottom right;
        overflow: hidden;

		ul {
			margin: 0;
        	padding: 0;
        	display: table;
        	
        	li { 
	        	
	        	color: white;
	        	list-style: none;
	        	display: inline-block;
	        	text-align: right;
	        	@media only screen and (max-width: 980px) {
					text-align: left;
				}
	        	
	        	a {
		        	color: inherit;
		        	text-decoration: none;
	        	}
	        	
	        	ul {
		        	
		        	width: 100%;
		        	
		        	li {
			        	display: block;
			        	font-weight: 300;
			        	margin: 0.5em 0;
			        	text-align: right;
			        	white-space: nowrap;
			        	@media only screen and (max-width: 980px) {
							text-align: left;
						}
			        	
			        	a:hover {
				        	text-decoration: underline;
			        	}
		        	}
	        	}
        	}
		}

        > ul {
        	
        	transform: $counter-skew;
            transform-origin: top left;
            width: 100%;
            margin-top: 1em;
            display: flex;
            padding-bottom: 18rem;
            @media only screen and (max-width: 980px) {
				display: block;
				column-count: 3;
				width: 90%;
			    padding-bottom: 18rem;
			    column-gap: 90px;
			    margin: 0 auto;
			    padding-bottom: 7rem;
			    padding-top: 3rem;
			}
			@media only screen and (max-width: 765px) {
				column-count: 2;
			}
			@media only screen and (max-width: 480px) {
				column-count: 1;
			}
        	
        	> li {
	        	
	        	font-weight: 600;
	        	margin: 0 1em;
	        	width: 100%;
	        	@media only screen and (max-width: 980px) {
					width: 100%;
					margin-bottom: 2rem;
					text-align: left;
				}
	        	
	        	&:last-of-type {
		        	margin-right: 4em;
	        	}
	        	
	        	a {
		        	font-weight: inherit;
	        	}	
        	}        	
        }  
	} 	
}


	 div.sub-footer {
        	width: 100%;
        	position: relative;


            p {
            	display: block;
            	background: rgba( black, .75);
            	text-align: center;
            	padding: 30px;
            	position: relative;
            	margin: 0;
				font-style:  italic;
            	color: rgba(#fff, .8);
            	font-size: 12px;

            	a {
            		text-decoration: none;
            		color: #fff;
            		transition: .3s ease;
            		opacity: .8;

	            	&:hover{
						opacity: 1;	        			
	        		}
            	}
            }
            
			.signature.adjust{
            font-family: Adjust;
		    color: #fff;
		    float: right;
		    transition: .5s;
		    position: absolute;
		    right: 4rem;
		    text-decoration: none;
		    bottom: 50%;
		    transform: translateY(50%) rotate(0deg);

			    @media only screen and (max-width: 414px) {
					font-family: Adjust;
				    color: #fff;
				    -webkit-transition: .5s;
				    transition: .5s;
				    position: absolute;
				    left: 50%;
				    text-decoration: none;
				    bottom: 16%;
				    transform: translate(0%, 50% ) rotate(0deg);
				}
		
			    &:hover{
					transform: translateY(50%) rotate(360deg);
					
					@media only screen and (max-width: 414px) {
					
				   		transform: translate(0%, 50% ) rotate(360deg);
					}
			    }
			}
        }  






