form.form_contact{
	input{
		float: none;
		background-color: #FFF;
		padding: 15px 30px;
		border:1px solid #D7D7D7;
		border-left: 4px solid #EE6878;
		width: 45%;
		
		@media only screen and (max-width: 550px) {
	    	width: 100%;
	    }

		&:nth-child(2n+0){
			margin-left: 9%;
			@media only screen and (max-width: 550px) {
	    		margin-left: 0;
	    	}
		}
	}
	textarea {
	    width: 100%;
	    height: 100px;
	    padding: 12px 20px;
	    box-sizing: border-box;
	    background-color: #FFF;
		border:1px solid #D7D7D7;
		border-left: 4px solid #EE6878;
	    font-size: 16px;
	    resize: none;
	}

	#optin--contact{
		float: left;
		margin: 0;
		width: 3%;
		margin-top: 1.5rem;

		@media only screen and (max-width: 375px) {
	    	width: 7%;
    		margin-top: 0.1rem;
	   	}
	}
	label{
		width: 65%;
    	font-size: 12px;
    	margin-top: 1.5rem;
    	margin-left: 1rem;
    	line-height: 1.4;

    	@media only screen and (max-width: 760px) {
	    	width: 90%;
	    	margin-bottom: 4rem;
	    }
	}
	span{
		color: #F25A44;
		font-size: 12px;
		font-weight: 600;
		font-style: italic;
		margin-top: 0.5rem;

	}
	button{
		position: absolute;
		display: block;
		bottom: 0;
		right: 0;
	}
}
section.contact_adress{
	div.content-container div.content-container-block.small{

		 div.landing-bg-img-contact_small{
                background-image: url('../img/adress_scope.jpg');
            }
		float: left;

		div div.info {
			p{
				font-weight: 400;
				margin: 2.4rem 0;
			}
			a{
				position: static;
				background: none;
				color: #EE6878;
				margin: 0;
				padding: 0;
				&:after{
					display: none;
				}
			}
		}
	}
	div.content-container div.content-container-block.medium{
		float: right;
		margin-top: -200px;
			@media only screen and (max-width: 1024px) {
	                    margin-top: 75px;
	        }
		.content-container-block-txt{
			padding: 0;
			a{
				background: transparent;
			    font-size: 12px;
			    font-style: initial;
			    padding: 0;
			    margin: 0;
			    position: static;
			    display: block;

			    &:after{
			    	display: none;
			    }
			}
		}

		#map-canvas{
			height: 650px;
		}
	}
}

section.contact__team{
	div.content-container div.content-container-block div.content-container-block-txt .contact__team--info{
		margin-top: 1rem;
		margin-bottom: 1rem;

		p{
			margin: 0;
			font-size: 15px;
			&:first-child{
				font-weight: 500;
			}
		}
		a{
			background: transparent;
		    position: static;
		    color: #ee6878;
		    padding: 0;
		    margin-top: 0;
		    font-size: 15px;

		    &:after{
		    	display: none;
		    }
		}
	}
}
 div.contact-team-management{
            background-image: url('../img/team_management.png');
        }
        div.contact-team-investor{
            background-image: url('../img/team_investor.png');
        }

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}